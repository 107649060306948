function LogoBar({}) {
  return (
    <div className="bg-white justify-center items-center gap-2 flex">
      <img src="/img/logo_black.svg" className="w-8 h-8 relative" />
      <img
        src="/img/logo_type_black.svg"
        className="w-16 h-8 justify-center items-center flex"
      />
    </div>
  );
}

export default LogoBar;
